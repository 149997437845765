.mycomponent {
    background: skyblue;
    border-radius: 8px;
    /* height: 400px; */
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 16px;

    margin: auto auto;
}

.btn {
    border: 2px solid red;
}